import React from 'react';
import dayjs from 'dayjs';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tag from '../Tag';

import { parseImgur } from '../../api/images';

import './index.scss';

const imageStyle = (headerImage, color) => ({
  backgroundColor: `#${color}`,
  backgroundImage: ` url(${headerImage})`,
});

const CardHeader = ({ url, image, title }) => (
  <Link to={url} href={url}>
    <div className="wrapper"><img title={title} src={image}/></div>
  </Link>
);

const Card = ({
  title,
  date,
  url,
  headerImage,
  headerBackgroundColor,
  description,
  tags,
}) => (
  <div className="col-sm-12 pb-4">
    <div className="custom-card">
    
    {headerImage && (
        <CardHeader
          url={url}
          image={headerImage}
          title={title}
        />
      )}
      <div className="data">
        <div className="content">
        <div className="dateleft">
          <div className="dateday">{dayjs(date).format('MMMM-DD-YYYY').split('-')[1]}</div>
          <div className="dateyear">{dayjs(date).format('MMMM-DD-YYYY').split('-')[0].substring(0, 3)}&nbsp;{dayjs(date).format('MMMM-DD-YYYY').split('-')[2]}</div>
        </div>
        <div className="dateright">

          <Link to={url} href={url}>
            <h2 className="title">{title}</h2>
          </Link>
          
          
          <div className="cardDescription">{description}</div>
          <div className="tagsRead">
            <div className="continusread">
            <Link to={url} href={url} className="continusreading">
              Read More
            </Link>
            </div>
            <div className="cardtags">
              {tags.map(name => (
                <Tag name={name} key={name} />
              ))}
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
);

Card.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string,
  url: PropTypes.string.isRequired,
  headerImage: PropTypes.string,
  headerBackgroundColor: PropTypes.string,
  description: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
};



Card.defaultProps = {
  headerImage: '',
  tags: [],
  date: '',
  headerBackgroundColor: '',
};

export default Card;
